* {
    box-sizing: border-box;
}

html {
    font-size: .2vw;
}

@media (min-width: 500px) and (orientation: portrait) {
    html {
        font-size: 1px;
    }
}

@media (orientation: landscape) {
    html {
        font-size: .15vh;
    }
}

body {
    background: black;
    font-size: 16rem;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

main {
    position: relative;
    height: calc(100% - 20rem);
    min-height: 640rem;
    margin: 10rem auto;
    width: 480rem;
}

.board {
    height: 480rem;
    padding-bottom: 100%;
    position: relative;
}

.board:not(.board_title) {
    cursor: pointer;
}

.board_tutorial {
    height: calc(480rem * 5 / 7);
    padding-bottom: calc(100% * 5 / 7);
}

.board__square {
    float: left;
    height: calc(480rem / 7.01);
    width: calc(480rem / 7.01);
}

.board__title {
    background: #000;
    color: white;
    font-size: 3.3em;
    font-weight: bold;
    line-height: 1.34em;
    height: calc(480rem / 7);
    position: absolute;
    top: calc(480rem / 7 * 2);
    left: calc(480rem / 7 * 1);
    text-align: center;
    text-transform: uppercase;
    width: calc(480rem / 7 * 3);
    z-index: 5;
}

.square {
    background: none;
    border: none;
    height: 100%;
    padding: 0;
    width: 100%;
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    opacity: .9;
    transition: transform 500ms, opacity 500ms;
}

.square::before {
    background: 50% 0 / 300% url('./assets/animals.png') no-repeat;
    content: '';
    display: block;
    padding-bottom: 100%;
}

.square_active {
    opacity: 1;
    transform: scale(1.2, 1.2);
}

.square_inactive {
    opacity: .6;
}

.square_type_0::before {
    background-position: 0 0;
}

.square_type_1::before {
    background-position: 50% 0;
}

.square_type_2::before {
    background-position: 100% 0;
}

.square_type_3::before {
    background: none;
}

.square_hidden {
    opacity: 0;
    transform: scale(0, 0);
}

.square_ready::before {
    animation: jump 300ms infinite alternate-reverse;
}

.main-menu {
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    line-height: 1.6em;
    margin: 8rem;
    position: absolute;
    right: 0;
}

.main-menu__action {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline;
    flex-grow: 0;
    flex-basis: 33%;
    font-size: 29rem;
    font-weight: 600;
    text-align: center;
}

.main-menu__action:first-child {
    text-align: left;
}

.main-menu__action:last-child {
    text-align: right;
}

.main-menu__action_inactive {
    visibility: hidden;
}

.main-menu__action a {
    color: inherit;
    text-decoration: none;
}

.menu {
    align-content: space-between;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    font-size: 1rem;
    justify-content: space-between;
    margin: 8rem;
}

.menu_main {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.menu__block {
    flex-basis: 100%;
}

 .menu__block + .menu__block {
    margin-top: 40rem;
}

.action {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 35rem;
    font-weight: bold;
    margin: 0 0 10rem;
    padding: 0;
    user-select: none;
}

.action a {
    color: inherit;
    text-decoration: none;
}

.stat__title {
    color: #fff;
    text-align: left;
    font-size: 29rem;
}

.stat__value {
    color: white;
    font-size: 42rem;
    text-align: right;
}

.stat__square {
    background: 50% 0 / 300% url('./assets/animals.png') no-repeat;
    background-position-x: 150%;
    float: right;
    height: 42rem;
    image-rendering: pixelated;
    margin-right: -4rem;
    margin-top: 4rem;
    width: 42rem;
}

.stat__square_type_0 {
    background-position-x: 0;
}

.stat__square_type_1 {
    background-position-x: 50%;
}

.stat__square_type_2 {
    background-position-x: 100%;
}

.message {
    color: #fff;
    font-size: 35rem;
    white-space: pre-line;
}

.message a {
    text-decoration: none;
    color: #8684ac;
}

.slider {
    display: flex;
    margin-bottom: 20rem;
}

.slider__square {
    width: 40rem;
}

.slider__bar-border {
    flex-grow: 1;
    height: 40rem;
    border: 4rem solid #fff;
    margin-left: 10rem;
    position: relative;
}

.slider__bar {
    background-color: #fff;
    height: 100%;
}

.options-headline {
    color: #fff;
    font-size: 42rem;
    font-weight: bold;
    margin-bottom: 20rem;
    margin-top: 10rem;
}

@keyframes jump {
    from {
        transform: translateY(.1em);
    }

    to {
        transform: translateY(-.1em);
    }
}
